<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/EditVisibility'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    formData: { type: Object, default () { return {} } }
  },
  data () {
    return {
      events: {
        roleUsersVisibility: 'core:role:RoleUsersVisibility'
      },
      title: 'Edycja widoczności użytkowników',
      okText: 'Zmień'
    }
  },
  created () {
    this.listenForVisibilitySelectChange()
  },
  methods: {
    submitForm () {
      api.request('core', 'put', `/roles/${this.$route.params.id}`, this.createDataToSend())
        .then(() => {
          this.toggleLoading()
          this.$emit('success', this.model)
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Zaktualizowano widoczność użytkowników'
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.errorNotify(error)
        })
    },
    listenForVisibilitySelectChange () {
      this.$events.on(this.events.roleUsersVisibility, (selectedVisibility) => {
        this.model.usersVisibility = selectedVisibility.value
      })
    },
    createDataToSend () {
      return this.model
    }
  }
}
</script>
