<script>
import BaseDetails from '../../../share/role/Details'
import ClientApplicationEditModal from './modal/Edit'
import UsersVisibilityEditionModal from './modal/EditVisibility'
import DetailsHeader from '../../../share/role/DetailsHeader.vue'
import api from '../../../../../api'

export default {
  components: {
    ClientApplicationEditModal,
    UsersVisibilityEditionModal,
    DetailsHeader
  },
  filters: {
    visibility: function (value) {
      return value === 1 ? 'Moje' : (value === 2 ? 'Mojego kontrahenta' : (value === 4 ? 'Mojej jednostki' : (value === 8 ? 'Wszystko' : 'Brak')))
    }
  },
  mixins: [
    BaseDetails
  ],
  data () {
    return {
      events: {
        editActionsAccess: 'core:role:editActionsAccess',
        editUsersVisibility: 'core:role:editUsersVisibility',
        editClientApplication: 'core:role:editClientApplication'
      },
      clientApplicationVisible: false,
      usersVisibilityVisible: false,
      role: {
        label: '',
        name: '',
        actions: [],
        contractorApps: [],
        usersVisibility: 1,
        inspectionVisibility: 1
      },
      allContractorApps: [],
      roleContractorApps: [],
      service: 'core'
    }
  },
  mounted () {
    this.$events.on(this.events.editActionsAccess, this.editActionsAccess)
    this.$events.on(this.events.editUsersVisibility, this.editUsersVisibility)
    this.$events.on(this.events.editClientApplication, this.editClientApplication)
  },
  methods: {
    getUserContractorAppsWithValues () {
      const sortAlphaNum = (a, b) => a.label.localeCompare(b.label, 'pl', { numeric: true })

      if (this.allContractorApps.length > 0) {
        this.roleContractorApps = this.allContractorApps.map(option => {
          return {
            id: option.id,
            label: option.label,
            name: option.name,
            value: !!this.role.contractorApps.find(element => element.id === option.id)
          }
        }).sort(sortAlphaNum)
      }
    },
    editClientApplication () {
      this.loadContractorApps()
      this.clientApplicationVisible = true
    },
    editActionsAccess () {
      this.$router.push({ name: 'admin_role_details_acl_edition' })
    },
    editUsersVisibility () {
      this.usersVisibilityVisible = true
    },
    loadContractorApps () {
      api.request(this.service, 'get', '/contractor-apps')
        .then((response) => {
          this.allContractorApps = response.data
          this.getUserContractorAppsWithValues()
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    }
  },
  template: `
    <div>
      <section class="content box box-default box-solid box-component roles-box">
        <details-header :role="role" :service="service" :controlModule="true">
          <div class="details-header--details">
            <h2>Aplikacje</h2>
              <ul v-if="role.contractorApps.length > 0">
                  <li v-for="(option, index) in role.contractorApps" :key="index">{{ option.label }}</li>
              </ul>
              <p v-else>Brak</p>
          </div>
          <div class="details-header--details">
            <h2>Poziom widoczności użytkowników</h2>
            <ul class="list-unstyled">
              <li>{{ role.usersVisibility | visibility }}</li>
            </ul>
          </div>
        </details-header>
        <details-content :role="role" :service="service"></details-content>
      </section>
      <role-edition-modal
          :show="roleEditionVisible"
          @close="roleEditionVisible = false"
          :service="service"
          mode="edit"
          :formData="role"
          @success="loadContent"
          >
      </role-edition-modal>
      <users-visibility-edition-modal
          :show="usersVisibilityVisible"
          @close="usersVisibilityVisible = false"
          @success="loadContent"
          :formData="role"
          :service="service"
          >
      </users-visibility-edition-modal>
      <client-application-edit-modal
            :show="clientApplicationVisible"
            @close="clientApplicationVisible = false"
            :formData="roleContractorApps"
            @success="function (clientApplicationsEdited) {
            role.contractorApps = Object.values(clientApplicationsEdited).filter(element => element.value)
            roleContractorApps = clientApplicationsEdited
            }"
            >
      </client-application-edit-modal>
    </div>
  `
}
</script>

<style scoped>
table.permissions td {
    width: 50px;
    text-align: center;
}
</style>
