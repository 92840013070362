<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/Edit'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    formData: { type: Array, default () { return [] } }
  },
  data () {
    return {
      title: 'Edycja aplikacji',
      okText: 'Zmień'
    }
  },
  methods: {
    submitForm () {
      api.request('core', 'put', `/contractor-apps/${this.$route.params.id}/roles`, this.createDataToSend())
        .then(() => {
          this.toggleLoading()
          this.$emit('success', this.model)
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Zaktualizowano aplikacje'
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.errorNotify(error)
        })
    },
    createDataToSend () {
      return { 'contractorApps': Object.values(this.model).filter(element => element.value).map(element => element.id) }
    }
  }
}
</script>
