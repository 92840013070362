<template>
  <form>
    <div class="modal-body form-group required-field">
      <label>Poziom widoczności:</label>
      <select
        v-model="selected"
        name="visibilityLevel"
        class="form-control"
        @change="selectValue($event.target.selectedOptions)"
      >
        <option
          v-for="(option, index) in visibilityOpts"
          :key="index"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'

export default {
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {}
  },
  data () {
    return {
      visibilitySelectHasBeenChanged: false,
      visibilityOpts: [
        { value: 1, label: 'Moje dane' },
        { value: 2, label: 'Dane mojego kontrahenta' },
        { value: 4, label: 'Dane mojej jednostki' },
        { value: 8, label: 'Wszystko' }
      ],
      urlActions: {
        getOptions: { service: 'core', method: 'get', url: '/contractor-apps' }
      }
    }
  },
  computed: {
    selected: {
      get: function () {
        return (this.value && this.value.hasOwnProperty('usersVisibility') && this.value.usersVisibility) ? this.value.usersVisibility : null
      },
      set: function (value) {
        return value
      }
    }
  },
  methods: {
    selectValue (selectedOptions) {
      this.$events.$emit('core:role:RoleUsersVisibility', selectedOptions[0])
      this.visibilitySelectHasBeenChanged = true
    }
  }
}
</script>
